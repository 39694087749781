import { useState } from 'react';
import './App.css';

import questions from './questions.json'

function Answer({text = '', correct = false, wrong = false, onClick = () => {}}){
  return (
    <div className={`question__option ${correct ? 'correct' : ''} ${wrong ? 'wrong' : ''}`} onClick={onClick}>
      {!correct && !wrong ? <div className='question__option__circle'>○</div> : null}
      {correct ? <div className='question__option__check'>&#x2713;</div> : null}
      {wrong ? <div className='question__option__cross'>&#x2717;</div> : null}
      <div className='question__option__text'>{text}</div>
    </div>
  )
}

function App() {
  const [listIndex, setListIndex] = useState(0)
  const [questionList, setQuestionList] = useState(Array.from({ length: questions.length }, (_, i) => i))
  const [wrongQuestionIndeces, setWrongQuestionIndeces] = useState([])
  const [score, setScore] = useState(0)
  const [selected, setSelected] = useState(null)

  const currentQuestion = questions[questionList[listIndex]]
  const isLastQuestion = (listIndex === questionList.length - 1)

  function handleClick(id){
    if (selected === null){
      setSelected(id)
      if (id === currentQuestion.correct){
        setScore(ca => ca + 1)
      } else {
        setWrongQuestionIndeces(w => [...w, questionList[listIndex]])
      }
    }
  }

  function next(){
    setSelected(null)
    setListIndex(qi => qi + 1)
  }

  function strengthen(){
    setSelected(null)
    setListIndex(0)
    setQuestionList(wrongQuestionIndeces)
    setWrongQuestionIndeces([])
    setScore(0)
  }

  function reset(){
    setSelected(null)
    setListIndex(0)
    setQuestionList(Array.from({ length: questions.length }, (_, i) => i))
    setWrongQuestionIndeces([])
    setScore(0)
  }

  return (
    <div className="App">
      <div className='question'>
        <div className='question__index'>{`Pregunta ${listIndex + 1}`}</div>
        <div className='question__title'>{currentQuestion.question}</div>
        <div className='question__options'>
          {currentQuestion.options.map((o, i) => (
            <Answer key={i} onClick={() => {handleClick(i)}} correct={selected !== null && currentQuestion.correct === i} wrong={selected !== null && selected === i && selected !== currentQuestion.correct} text={o} />
          ))}
        </div>
        <div className='buttons'>
          {selected !== null ? <div className='question__counter'>{`${score}/${listIndex + 1}`}</div> : null}
          {selected !== null  && !isLastQuestion? <div className={'button blue'} onClick={next}>Siguiente →</div> : null}
          {selected !== null && isLastQuestion ? <div className={'button red'} onClick={reset}>Reiniciar ↻</div> : null}
          {selected !== null && isLastQuestion && wrongQuestionIndeces.length > 0 ? <div className={'button yellow'} onClick={strengthen}>Reforzar</div> : null}
        </div>
      </div>
    </div>
  );
}

export default App;
